import React, { Component} from 'react';
import { Container, Title, Columns, Column, Field, Label, Control, Input, Help, TextArea, Card, CardContent, Tag} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

class Calculator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
                FixedPrice: '',
                Months: '36',
                OneTime: "0",
                MontlyPrice: "0"
        };

        this.handleInput = this.handleInput.bind(this);
      }

    handleInput(name, e) {
       
        this.setState(
            
            {
                    [name]: e.target.value,
                    
            }
        )
    }


    render() {
        const headerStyle = {
            color: 'blue',
          };
          const linkStyle = {
            'user-select': 'all',
            fontFamily: 'monospace',
            fontSize: '70%'
          };
          var MontlyPriceValue = ((this.state.FixedPrice - this.state.OneTime) / this.state.Months) 
          //this.setState({MontlyPrice: MontlyPriceValue})
        return (
            <div>
                <Title>Abonnementen calculator</Title>
                <Columns isCentered>
                <Column isSize='full'>
                <Field>
                    <Label>
                        Fixed prijs offerte:
                    </Label>
                    <Control>
                        <Input type="number" maxlenght="60" placeholder='bijv. 1200' onChange={(e) => this.handleInput('FixedPrice', e)} value={this.state.FixedPrice} />
                    </Control>
                </Field>
                <Field>
                    <Label>
                        Eenmalige aanbetaling:
                    </Label>
                    <Control>
                        <Input type="number" maxlenght="60" placeholder='bijv. 250' onChange={(e) => this.handleInput('OneTime', e)} value={this.state.OneTime} />
                    </Control>
                </Field>
                <Field>
                    <Label>
                        Aantal maanden:
                    </Label>
                    <Control>
                        <Input type="range" min="1" max="60" step="11" onChange={(e) => this.handleInput('Months', e)} value={this.state.Months} />
                        {this.state.Months}
                    </Control>
                </Field>
                
                </Column>
                </Columns>
                <Columns isCentered>
                <Column isSize='full'>
                    <Card>
                        
                        <CardContent style={linkStyle}>
                          € {MontlyPriceValue} euro per maand
                        </CardContent>
                    </Card>
                </Column>
                </Columns>
            </div>
        )
    }
}

export default Calculator;