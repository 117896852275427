import React, { Component} from 'react';
import { Title, Columns, Column, Field, Label, Control, Input, Button, Checkbox, Card, CardContent} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

import {GenerateText} from "../utils/GenerateText";

class Lorem extends Component {
  constructor(props) {
    super(props);
        this.state = {
        length: 1,
        linebreak: true,
        };
  }

  componentDidMount() {
    this.generateTxt();
  }
  generateTxt() {
    const {linebreak, length } = this.state;
    return <GenerateText lineBreak={linebreak} times={length} />;
  }

  handleCheckbox(e) {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });
    this.generateTxt();
  }

  render() {

    const linkStyle = {
        userSelect: 'all',
        fontFamily: '"Courier", cursive, sans-serif',
        fontSize: '80%'
      };

    return (
      <div>
           <Title >Lorem Ipsum generator</Title>
           
            <Title  isSize={5}>Pas tekst aan</Title>
            <Columns isCentered>
            
                <Column isSize='full'>
                       <Field>
                        <Control>
                            <Checkbox name="linebreak" checked={this.state.linebreak}  onChange={e => this.handleCheckbox(e)}> Linebreaks </Checkbox>                           
                        </Control>
                    </Field>
                    <Field>
                        <Control>
                            <Label>Aantal paragrafen</Label>
                            <Input type="number" min="1" max="20" value={this.state.length} onChange={e => {
                                this.setState({ length: e.target.value });
                                this.generateTxt();
                            }} />
                        </Control>
                    </Field>
                </Column>
            </Columns>
            <Columns isCentered>
                <Column isSize='full'>
                    <Card>
                        <CardContent style={linkStyle}>{this.generateTxt()}
                    </CardContent>
                </Card>
            </Column>
            </Columns>
        </div>
    );
  }
}

export default Lorem;