import React, { Component} from 'react';
import { Container, Title, Columns, Column, Field, Label, Control, Input, Help, TextArea, Card, CardContent, Tag} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

class Wordpress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
                old: '',
                new: '',
                prefix: "wp",
        };

        this.handleInput = this.handleInput.bind(this);
      }

    handleInput(name, e) {
        this.setState(
            
            {
                    [name]: e.target.value,
            }
        )
    }


    render() {
        const headerStyle = {
            color: 'blue',
          };
          const linkStyle = {
            'user-select': 'all',
            fontFamily: 'monospace',
            fontSize: '70%'
          };

        return (
            <div>
                <Title>Wordpress Database Transfer</Title>
                <Columns isCentered>
                <Column isSize='full'>
                <Field>
                    <Label>
                        Oude website URL
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Oude url incl http' onChange={(e) => this.handleInput('old', e)} value={this.state.old} />
                    </Control>
                </Field>
                <Field>
                    <Label>
                        Nieuwe website URL
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Nieuwe url incl http' onChange={(e) => this.handleInput('new', e)} value={this.state.new} />
                    </Control>
                </Field>
                <Field>
                    <Label>
                        Database table prefix
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='wp' onChange={(e) => this.handleInput('prefix', e)} value={this.state.prefix} />
                    </Control>
                </Field>
                
                </Column>
                </Columns>
                <Columns isCentered>
                <Column isSize='full'>
                    <Card>
                        
                        <CardContent style={linkStyle}>
                            UPDATE {this.state.prefix}_options SET option_value = replace(option_value, '{this.state.old}', '{this.state.new}') WHERE option_name = 'home' OR option_name = 'siteurl'; <br />
                            UPDATE {this.state.prefix}_posts SET guid = REPLACE (guid, '{this.state.old}', '{this.state.new}'); <br />
                            UPDATE {this.state.prefix}_posts SET post_content = REPLACE (post_content, '{this.state.old}', '{this.state.new}'); <br />
                            UPDATE {this.state.prefix}_postmeta SET meta_value = REPLACE (meta_value, '{this.state.old}', '{this.state.new}'); 
                        </CardContent>
                    </Card>
                </Column>
                </Columns>
            </div>
        )
    }
}

export default Wordpress;