import React, { Component} from 'react';
import { Container, Title, Columns, Column, Field, Label, Control, Input, Checkbox, Help, TextArea, Card, CardContent, Tag} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

class Imap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            domeinnaam: '',
            adresOld: '62.221.204.83',
            OldSSL: false,
            adresNew: "server2.djpcloud.nl",
            NewSSL: false,
            mail: "",
            password: "",
        };

        this.handleInput = this.handleInput.bind(this);
      }

    handleInput(name, e) {
        this.setState(
            
            {
                    [name]: e.target.value,
            }
        )
    }


    render() {
        const headerStyle = {
            color: 'blue',
          };
          const linkStyle = {
            'user-select': 'all',
            fontFamily: 'monospace',
            fontSize: '70%'
          };

          const ssl1 = this.state.OldSSL ? '--ssl1' : '';
          const ssl2 = this.state.NewSSL ? '--ssl2' : '';

        return (
            <div>
                <Title>IMAP Sync</Title>
                <Columns isCentered>
                <Column isSize='full'>
                <Field>
                    <Label>
                        Domeinnaam
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Domeinnaam' onChange={(e) => this.handleInput('domeinnaam', e)} value={this.state.domeinnaam} />
                    </Control>
                </Field>
                <br />
                <Field>
                    <Label>
                        Adres huidige server
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='IP of hostname oude server' onChange={(e) => this.handleInput('adresOld', e)} value={this.state.adresOld} />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Checkbox name="symbol" checked={this.state.OldSSL}  onChange={() => this.setState({'OldSSL': this.state.OldSSL ? false : true})}> SSL verbinding </Checkbox>                           
                    </Control>
                </Field>
                <br />
                <Field>
                    <Label>
                        Adres nieuwe server
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='IP of hostname nieuwe server' onChange={(e) => this.handleInput('adresNew', e)} value={this.state.adresNew} />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Checkbox name="symbol" checked={this.state.NewSSL}  onChange={() => this.setState({'NewSSL': this.state.NewSSL ? false : true})}> SSL verbinding </Checkbox>                           
                    </Control>
                </Field>
                <br />
                <Field>
                    <Label>
                        E-mailadres (alleen voor @)
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Mailadres' onChange={(e) => this.handleInput('mail', e)} value={this.state.mail} />
                    </Control>
                </Field>
                <Field>
                    <Label>
                        Wachtwoord
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Wachtwoord' onChange={(e) => this.handleInput('password', e)} value={this.state.password} />
                    </Control>
                </Field>
                
                </Column>
                </Columns>
                <Columns isCentered>
                <Column isSize='full'>
                    {
                    this.state.domeinnaam && this.state.mail && this.state.password ?
                    <Card>
                        
                        <CardContent style={linkStyle}>
                                imapsync --host1 {this.state.adresOld} {ssl1} --user1 {this.state.mail}@{this.state.domeinnaam} --password1 {this.state.password} --host2 {this.state.adresNew} {ssl2} --user2 {this.state.mail}@{this.state.domeinnaam} --password2 {this.state.password}
                        </CardContent>
                    </Card>
                    : "" }
                </Column>
                </Columns>
            </div>
        )
    }
}

export default Imap;