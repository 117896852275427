import React, { Component} from 'react';
import { Title, Columns, Column, Field, Label, Control, Input, Help, TextArea, Card, CardHeaderTitle, CardContent} from 'bloomer';

class Sea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
                title1: '',
                title1length: 0,
                title1lengthWarning: false,
                title2: '',
                title2length: 0,
                title2lengthWarning: false,
                title3: '',
                title3length: 0,
                title3lengthWarning: false,
                desc1: '',
                desc1length: 0,
                desc1lengthWarning: false,
                desc2: '',
                desc2length: 0,
                desc2lengthWarning: false,
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleDiscription = this.handleDiscription.bind(this);
      }

    handleInput(name, e) {
        const Warning = e.target.value.length > 30 ? true : false;
        this.setState(
            
            {
                    [name]: e.target.value,
                    [name+'length']: e.target.value.length,
                    [name+'lengthWarning']: Warning,
            }
        )
    }

    handleDiscription(name, e) {
        const Warning = e.target.value.length > 90 ? true : false;
        this.setState(
            {
                    [name]: e.target.value,
                    [name+'length']: e.target.value.length,
                    [name+'lengthWarning']: Warning,
            }
        )
    }

    render() {
        const headerStyle = {
            color: 'blue',
          };
          const linkStyle = {
            color: 'green',
          };

        return (
            <div>
                <Title>Google SEA Preview Tool</Title>
                <Columns isCentered>
                <Column isSize='1/2'>
                <Field>
                    <Label>Kop 1</Label>
                    <Control>
                        <Input type="text" maxlenght="30" placeholder='Kop 1' onChange={(e) => this.handleInput('title1', e)} value={this.state.title1} />
                        <Help isColor={this.state.title1lengthWarning ? 'danger' : 'success'}>Maximaal 30 tekens - Je gebruikt nu {this.state.title1length} tekens
                        <br /> <a href="https://support.google.com/google-ads/answer/1704389?hl=nl" target="_blank" rel="noopener noreferrer"> Meer info over tekstadvertenties</a>
                        </Help>
                    </Control>
                </Field>
                <Field>
                    <Label>Kop 2</Label>
                    <Control>
                        <Input type="text" maxlenght="30" placeholder='Kop 2' onChange={(e) => this.handleInput('title2', e)} value={this.state.title2} />
                        <Help isColor={this.state.title2lengthWarning ? 'danger' : 'success'}>Maximaal 30 tekens - Je gebruikt nu {this.state.title2length} tekens
                        <br /> <a href="https://support.google.com/google-ads/answer/7056544?hl=nl" target="_blank" rel="noopener noreferrer"> Meer info over uitgebreide tekstadvertenties</a>
                        </Help>
                    </Control>
                </Field>
                <Field>
                    <Label>Kop 3</Label>
                    <Control>
                        <Input type="text" maxlenght="30" placeholder='Kop 3' onChange={(e) => this.handleInput('title3', e)} value={this.state.title3} />
                        <Help isColor={this.state.title3lengthWarning ? 'danger' : 'success'}>Maximaal 30 tekens - Je gebruikt nu {this.state.title3length} tekens
                        <br /> <a href="https://support.google.com/google-ads/answer/7056544?hl=nl" target="_blank" rel="noopener noreferrer"> Meer info over uitgebreide tekstadvertenties</a>
                        </Help>
                    </Control>
                </Field>
                <Field>
                    <Label>Beschrijving 1</Label>
                    <Control>
                        <TextArea maxlenght="90" placeholder='Beschrijving 1' onChange={(e) => this.handleDiscription('desc1', e)} value={this.state.desc1} />
                        <Help isColor={this.state.desc1lengthWarning ? 'danger' : 'success'}>Maximaal 90 tekens - Je gebruikt nu {this.state.desc1length} tekens
                        <br /> <a href="https://support.google.com/google-ads/answer/1704389?hl=nl" target="_blank" rel="noopener noreferrer"> Meer info over tekstadvertenties</a>
                        </Help>
                    </Control>
                </Field>
                <Field>
                    <Label>Beschrijving 2</Label>
                    <Control>
                        <TextArea maxlenght="90" placeholder='Beschrijving 2' onChange={(e) => this.handleDiscription('desc2', e)} value={this.state.desc2} />
                        <Help isColor={this.state.desc2lengthWarning ? 'danger' : 'success'}>Maximaal 90 tekens - Je gebruikt nu {this.state.desc2length} tekens
                        <br /> <a href="https://support.google.com/google-ads/answer/7056544?hl=nl" target="_blank" rel="noopener noreferrer"> Meer info over uitgebreide tekstadvertenties</a>
                        </Help>
                    </Control>
                </Field>
                </Column>
                <Column isSize='1/2'>
                    <Card>
                        
                        <CardContent>
                        <h5 style={headerStyle}>
                        { this.state.title1 ? this.state.title1: "Voorbeeld Kop 1 max 30 tekens" }
                        { this.state.title2 ? <span dangerouslySetInnerHTML={{"__html": " | " + this.state.title2}}></span> : "" }
                        { this.state.title3 ? <span dangerouslySetInnerHTML={{"__html": " | " + this.state.title3}}></span> : "" }
                       
                        </h5>
                            <small style={linkStyle}>ADVERTENTIE www.duidelijksas.nl</small><br />
                            { this.state.desc1 ? <span dangerouslySetInnerHTML={{"__html": "" + this.state.desc1}}></span> : "Voorbeeld beschrijving max 90 tekens" }
                            { this.state.desc2 ? <span dangerouslySetInnerHTML={{"__html": " " + this.state.desc2}}></span> : "" }
                        </CardContent>
                    </Card>
                </Column>
                </Columns>
            </div>
        )
    }
}

export default Sea;