import React from 'react';

import { Container, Section, Columns, Column, Title} from 'bloomer';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

import Sea from './components/sea';
import Seo from './components/seo';
import Urlbuilder from './components/urlbuilder';
import Wordpress from './components/wordpress';
import Password from './components/password';
import Lorem from './components/lorem';
import Calculator from './components/calculator';
import Imap from './components/imap';
import Nav from './components/nav';
import Dashboard from './components/dashboard';
import Dnschecker from './components/dnschecker';
import Ipchecker from './components/ipchecker';

function App() {
  return (
    <Router>
      <div>
      <Section>
        <Container>
          <Columns isCentered>
            <Column isSize='1/4'>
                <Nav />
            </Column>
            <Column>
            <Route exact path="/" component={Dashboard} />
            <Route path="/sea" component={Sea} />
            <Route path="/seo" component={Seo} />
            <Route path="/url-builder" component={Urlbuilder} />
            <Route path="/password" component={Password} />
            <Route path="/wordpress" component={Wordpress} />
            <Route path="/imap" component={Imap} />
            <Route path="/lorem" component={Lorem} />
            <Route path="/calculator" component={Calculator} />
            <Route path="/dns" component={Dnschecker} />
            <Route path="/ip" component={Ipchecker} />
               
            </Column>
        </Columns>
            
                
                
        </Container>
        </Section>
      </div>
    </Router>
  );
}

export default App;
