import React, { Component} from 'react';
import { Title, Columns, Column, Field, Label, Control, Input, Help, TextArea, Card, CardContent, Tag} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

class Seo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
                title1: '',
                title1length: 0,
                title1lengthWarning: false,
                desc1: '',
                desc1length: 0,
                desc1lengthWarning: false,
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleDiscription = this.handleDiscription.bind(this);
      }

    handleInput(name, e) {
        const Warning = e.target.value.length > 60 ? true : false;
        this.setState(
            
            {
                    [name]: e.target.value,
                    [name+'length']: e.target.value.length,
                    [name+'lengthWarning']: Warning,
            }
        )
    }

    handleDiscription(name, e) {
        const Warning = e.target.value.length > 160 ? true : false;
        this.setState(
            {
                    [name]: e.target.value,
                    [name+'length']: e.target.value.length,
                    [name+'lengthWarning']: Warning,
            }
        )
    }

    render() {
        const headerStyle = {
            color: 'blue',
          };
          const linkStyle = {
            color: 'green',
          };

        return (
            <div>
                <Title>Google SEO Preview Tool</Title>
                <Columns isCentered>
                <Column isSize='1/2'>
                <Field>
                    <Label>
                        Titel
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Kop 1' onChange={(e) => this.handleInput('title1', e)} value={this.state.title1} />
                        <Help isColor={this.state.title1lengthWarning ? 'danger' : 'success'}>Maximaal 50 - 60 tekens - Je gebruikt nu {this.state.title1length} tekens
                        <br /> <a href="https://moz.com/learn/seo/title-tag" target="_blank" rel="noopener noreferrer"> Meer info over de title-tag</a>
                        </Help>
                    </Control>
                </Field>
                <Field>
                    <Label>Meta Description</Label>
                    <Control>
                        <TextArea maxlenght="160" placeholder='Beschrijving 1' onChange={(e) => this.handleDiscription('desc1', e)} value={this.state.desc1} />
                        <Help isColor={this.state.desc1lengthWarning ? 'danger' : 'success'}>Maximaal 155 - 160 tekens - Je gebruikt nu {this.state.desc1length} tekens
                        <br /> <a href="https://moz.com/learn/seo/meta-description" target="_blank" rel="noopener noreferrer"> Meer info over de meta-description-tag</a>
                        </Help>
                    </Control>
                </Field>
                </Column>
                <Column isSize='1/2'>
                    <Card>
                        
                        <CardContent>
                        <h5 style={headerStyle}>
                        { this.state.title1 ? this.state.title1: "Voorbeeld Kop 1 max 60 tekens" }
                        { this.state.title2 ? <span dangerouslySetInnerHTML={{"__html": "<br />" + this.state.title2}}></span> : "" }
                        { this.state.title3 ? <span dangerouslySetInnerHTML={{"__html": "<br />" + this.state.title3}}></span> : "" }
                       
                        </h5>
                            <small style={linkStyle}>https://www.duidelijksas.nl</small><br />
                            { this.state.desc1 ? <span dangerouslySetInnerHTML={{"__html": "" + this.state.desc1}}></span> : "Voorbeeld beschrijving max 155 - 160 tekens" }
                            { this.state.desc2 ? <span dangerouslySetInnerHTML={{"__html": "<br />" + this.state.desc2}}></span> : "" }
                        </CardContent>
                    </Card>
                </Column>
                </Columns>
            </div>
        )
    }
}

export default Seo;