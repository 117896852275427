import React, { Component} from 'react';
import { Title, Columns, Column, Field, Label, Control, Input, Help, Card, CardContent, CardHeader, CardHeaderTitle} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

class Urlbuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
                url: '',
                campagne: 'google',
                medium: 'adwords',
                naam: '',
                term: '',
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleDiscription = this.handleDiscription.bind(this);
      }

    handleInput(name, e) {
        const Warning = e.target.value.length > 60 ? true : false;
        this.setState(
            
            {
                    [name]: e.target.value,
                    [name+'length']: e.target.value.length,
                    [name+'lengthWarning']: Warning,
            }
        )
    }

    handleDiscription(name, e) {
        const Warning = e.target.value.length > 160 ? true : false;
        this.setState(
            {
                    [name]: e.target.value,
                    [name+'length']: e.target.value.length,
                    [name+'lengthWarning']: Warning,
            }
        )
    }

    render() {
        const headerStyle = {
            color: 'blue',
          };
          const linkStyle = {
            'user-select': 'all',
          };

        return (
            <div>
                <Title>Google Campagne URL Builder</Title>
                <p>Deze functie zorgt ervoor dat je eenvoudig campagne URL variabelen kunt toevoegen aan URL's zodat je eenvoudig de statistieken kunt tracken in Google Analytics. <br /><br /></p>
                
                <Columns isCentered>
                <Column isSize='ful'>
                <Field>
                    <Label>
                        Website URL
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Website URL' onChange={(e) => this.handleInput('url', e)} value={this.state.url} />
                        <Help isColor='success'><a href="https://ga-dev-tools.appspot.com/campaign-url-builder/" target="_blank" rel="noopener noreferrer"> Meer info</a>
                        </Help>
                    </Control>
                </Field>
                <Field>
                    <Label>
                        Campagne bron
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Campagne bron' onChange={(e) => this.handleInput('campagne', e)} value={this.state.campagne} />
                        <Help isColor='success'>The referrer: (e.g. google, newsletter)
                        | <a href="https://ga-dev-tools.appspot.com/campaign-url-builder/" target="_blank" rel="noopener noreferrer"> Meer info</a>
                        </Help>
                    </Control>
                </Field>
                <Field>
                    <Label>
                        Campagne medium
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Campagne medium' onChange={(e) => this.handleInput('medium', e)} value={this.state.medium} />
                        <Help isColor='success'>Marketing medium: (e.g. cpc, banner, email)
                        | <a href="https://ga-dev-tools.appspot.com/campaign-url-builder/" target="_blank" rel="noopener noreferrer"> Meer info</a>
                        </Help>
                    </Control>
                </Field>
                <Field>
                    <Label>
                        Campagne naam
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Campagne naam' onChange={(e) => this.handleInput('naam', e)} value={this.state.naam} />
                        <Help isColor='success'>Product, promo code, or slogan (e.g. spring_sale)
                        | <a href="https://ga-dev-tools.appspot.com/campaign-url-builder/" target="_blank" rel="noopener noreferrer"> Meer info</a>
                        </Help>
                    </Control>
                </Field>
                <Field>
                    <Label>
                        Campagne zoekwoord
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Campagne zoekterm' onChange={(e) => this.handleInput('term', e)} value={this.state.term} />
                        <Help isColor='success'>Identify the paid keywords
                        | <a href="https://ga-dev-tools.appspot.com/campaign-url-builder/" target="_blank" rel="noopener noreferrer"> Meer info</a>
                        </Help>
                    </Control>
                </Field>
                
                </Column>
                </Columns>
                <Columns>
                <Column isSize='full'>
                    <Card>
                        <CardHeader>
                            <CardHeaderTitle>Gegenereerde URL</CardHeaderTitle>
                        </CardHeader>
                        {this.state.url ? 
                        <CardContent>
                            <div style={linkStyle}>
                            { this.state.url ? this.state.url: "" }
                            { this.state.campagne ? "?utm_source=" + encodeURIComponent(this.state.campagne.trim()) : "" }
                            { this.state.medium ? "&utm_medium=" + encodeURIComponent(this.state.medium.trim()) : "" }
                            { this.state.naam ? "&utm_campaign=" +encodeURIComponent(this.state.naam.trim()) : "" }
                            { this.state.term ? "&utm_term=" + encodeURIComponent(this.state.term.trim()) : "" }
                            </div>
                        </CardContent>
                        : ""}
                    </Card>
                </Column>
                </Columns>
            </div>
        )
    }
}

export default Urlbuilder;