import React, { Component} from 'react';
import axios from 'axios';
import { Container, Title, Content, Columns, Column, Field, Label, Control, Input, Help, TextArea, Card, CardContent, Tag} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

class Dnschecker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Dns:[],
            domeinnaam: ''
        };

        this.handleInput = this.handleInput.bind(this);

      }

    runDNS(domain) {
       let stateThis = this
        axios({
            method: 'get',
            url: 'https://dns.google.com/resolve?name='+domain+'&type=ANY',
            responseType: 'json'
          })
            .then(function (response) {
                stateThis.setState({
                    Dns: response.data
                });
                console.log('DNS ',stateThis.state.Dns);
            });
    }

    componentDidMount() {
        let current = this
        //this.runDNS();
    }
    componentDidUpdate() {
        
        console.log('DNS update',this.state.Dns);
    }

    handleInput = async (name, e) => {
        this.setState({
                [name]: e.target.value,
            })
       await this.runDNS(e.target.value);
    }




    render() {

        const Anwser = this.state.Dns.Answer ? this.state.Dns.Answer.map((item, index) =>
        // Only do this if items have no stable IDs
        <tr key={index}>
          <td>{item.name}</td>
          <td>{(function() {
        switch (item.type) {
          case 2:
            return 'NS';
          case 1:
            return 'A';
          case 28:
            return 'AAAA';
          case 15:
            return 'MX';
          case 16:
            return 'TXT';
          case 6:
            return 'SOA';
          default:
            return null;
        }
      })()}</td>
          <td>{item.TTL}</td>
          <td>{item.data}</td> 
        </tr>
      ) : false;
        console.log('DNS update',this.state.Dns);
        return (
            <div>
                <Title>DNS Checker</Title>
                
                <Field>
                    <Label>
                        Domeinnaam
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" placeholder='Domeinnaam' onChange={(e) => this.handleInput('domeinnaam', e)} value={this.state.domeinnaam} />
                    </Control>
                </Field>
                <Content>
                        <CardContent>
                        {Anwser ? 
                            <div>
                           
                            {this.state.Dns ? 
                                <div>
                               
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Naam</th>
                                        <th>Type</th>
                                        <th>TTL</th>
                                        <th>Data</th>
                                    </tr>
                                    </thead>
                                    <tbody>{Anwser}</tbody>
                                </table>
                                </div>
                                : ''}
                                 Status: {this.state.Dns.Status}<br />
                                 Comment: {this.state.Dns.Comment || '-'}
                            </div>
                            : ''}
                        </CardContent>
                </Content>

            </div>
        )
    }
}

export default Dnschecker;