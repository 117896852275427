import React, { Component} from 'react';
import axios from 'axios';
import { Container, Title, Content, Columns, Column, Field, Label, Control, Input, Help, TextArea, Card, CardContent, Tag} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Quote:''
        };

      }

    componentDidMount() {
        let current = this
        axios({
            method: 'get',
            url: 'https://quotes.rest/qod',
            responseType: 'json'
          })
            .then(function (response) {
                console.log(response.data);
                current.setState({
                    Quote: response.data.contents.quotes[0]
                  });
            });
    }


    render() {

        return (
            <div>
                <Title>Dashboard</Title>
                

                <Content>
                    <Title>{this.state.Quote.Title}</Title>
                    <blockquote>{this.state.Quote.quote}</blockquote>
                </Content>

            </div>
        )
    }
}

export default Dashboard;