import React, { Component} from 'react';
import axios from 'axios';
import { Container, Title, Content, Columns, Column, Field, Label, Control, Input, Help, TextArea, Card, CardContent, Tag} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

class Ipchecker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            IP: '',
            domeinnaam: ''
        };
      }

    runDNS(domain) {
       let stateThis = this
        axios({
            method: 'get',
            url: 'https://api.ipify.org?format=json',
            responseType: 'json'
          })
            .then(function (response) {
                console.log(response.data.ip)
                stateThis.setState({
                    IP: response.data.ip
                });
            });
    }

    componentDidMount() {
        this.runDNS();
    }
    componentDidUpdate() {
        
        console.log('IP update',this.state.IP);
    }

    




    render() {

        console.log('DNS update',this.state.IP);
        return (
            <div>
                <Title>Eigen IP Checker</Title>
                
                <Field>
                    <Label>
                        Ip adres
                    </Label>
                    <Control>
                        <Input type="text" maxlenght="60" readOnly  value={this.state.IP} />
                    </Control>
                </Field>
                <Content>
                        <CardContent>
                        
                        </CardContent>
                </Content>

            </div>
        )
    }
}

export default Ipchecker;