import React, { Component } from 'react';

export class GenerateText extends Component{
  constructor(props) {
    super(props);
    this.state = {
        characters: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis vitae risus non viverra. Vivamus condimentum sed ipsum vitae ullamcorper. Vestibulum ac mi malesuada, fermentum magna non, tincidunt ligula. Nam in elit sapien.",
    };

  }


    render() {
      const times = this.props.times;
      const displayText = [];
      for (let t = 0; t < times; t += 1) {
        // Determine whether a line break has to be added for each intermediate occurence of the repetitions.
        if (times > 1 && t > 0 && this.props.lineBreak) displayText.push(<br key={'a'+{t}} />);
        displayText.push(
          <div key={t}>
            {this.state.characters}
          </div>
        );
      }
  
      return (
        <div>{displayText}</div>
      );
    }

  }