import React, { Component} from 'react';
import { Title, Columns, Column, Field, Label, Control, Input, Button, Checkbox, Card, CardContent} from 'bloomer';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

import {RandomPassword} from "../utils/RandomPassword";

class Password extends Component {
  constructor(props) {
    super(props);
        this.state = {
        length: 12,
        pwd: "",
        upperCase: true,
        lowerCase: true,
        numeric: true,
        symbol: false,
        };
  }

  componentDidMount() {
    this.generatePwd();
  }
  generatePwd() {
    const { upperCase, lowerCase, numeric, symbol, length } = this.state;
    let pwd = new RandomPassword()
      .setLength(length)
      .setLowerCase(lowerCase)
      .setUpperCase(upperCase)
      .setNumberCase(numeric)
      .setSymbol(symbol)
      .generate();
    this.setState({ pwd });
  }

  handleCheckbox(e) {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked
    });
    this.generatePwd();
  }

  render() {

    const linkStyle = {
        userSelect: 'all',
        fontFamily: '"Courier", cursive, sans-serif',
        fontSize: '120%'
      };

    return (
      <div>
           <Title >Wachtwoord generator</Title>
           <Columns isCentered>
                <Column isSize='full'>
                <Card>
                        <CardContent style={linkStyle}>{this.state.pwd}
                    </CardContent>
                </Card>
            </Column>
            </Columns>
            <Title  isSize={5}>Pas het wachtwoord aan</Title>
            <Columns isCentered>
            
                <Column isSize='full'>
                    <Field>
                        <Control>
                            <Checkbox name="upperCase" checked={this.state.upperCase}  onChange={e => this.handleCheckbox(e)}> Hoofdletters </Checkbox>                           
                        </Control>
                    </Field>
                    <Field>
                        <Control>
                            <Checkbox name="lowerCase" checked={this.state.lowerCase}  onChange={e => this.handleCheckbox(e)}> Kleine letters </Checkbox>                           
                        </Control>
                    </Field>
                    <Field>
                        <Control>
                            <Checkbox name="numeric" checked={this.state.numeric}  onChange={e => this.handleCheckbox(e)}> Nummers </Checkbox>                           
                        </Control>
                    </Field>
                    <Field>
                        <Control>
                            <Checkbox name="symbol" checked={this.state.symbol}  onChange={e => this.handleCheckbox(e)}> Symbolen </Checkbox>                           
                        </Control>
                    </Field>
                    <Field>
                        <Control>
                            <Label>Wachtwoord lengte</Label>
                            <Input type="number" min="8" max="40" value={this.state.length} onChange={e => {
                                this.setState({ length: e.target.value });
                                this.generatePwd();
                            }} />
                        </Control>
                    </Field>
                    <Field>
                        <Control>
                            <Button isColor='primary' onClick={() => {this.generatePwd(); }}>Maak een random wachtwoord</Button>
                        </Control>
                    </Field>

                </Column>
            </Columns>
        </div>
    );
  }
}

export default Password;