import React, { Component} from 'react';
import logo from '../img/logo-sas.png';
import { Menu, MenuLabel, MenuList, Image} from 'bloomer';

import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
class Nav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        };
      }

    render() {
        return (
            <div>
                <Image isSize='128x128' src={logo} />
            <Menu>
                <MenuLabel>Menu</MenuLabel>
                <MenuList>
                    <li><NavLink to='/' exact={true} activeClassName="is-active">Dashboard</NavLink></li>
                </MenuList>
                <MenuLabel>Online marketing</MenuLabel>
                <MenuList>
                    <li><NavLink to='/seo' activeClassName="is-active">SEO Previewer</NavLink></li>
                    <li><NavLink to='/sea' activeClassName="is-active">SEA Previewer</NavLink></li>
                    <li><NavLink to='/url-builder' activeClassName="is-active">Google Campagne URL builder</NavLink></li>
                </MenuList>
                <MenuLabel>Handige Tools</MenuLabel>
                <MenuList>
                    <li><NavLink to='/password' activeClassName="is-active">Wachtwoord generator</NavLink></li>
                    <li><NavLink to='/lorem' activeClassName="is-active">Lorem Ipsum generator</NavLink></li>
                    <li><NavLink to='/ip' activeClassName="is-active">Eigen IP Checker</NavLink></li>
                </MenuList>
                <MenuLabel>Dev Tools</MenuLabel>
                <MenuList>
                    <li><NavLink to='/wordpress' activeClassName="is-active">Wordpress DB Transfer</NavLink></li>
                    <li><NavLink to='/imap' activeClassName="is-active">Imap sync</NavLink></li>
                    <li><NavLink to='/dns' activeClassName="is-active">DNS Checker</NavLink></li>
                </MenuList>
                <MenuLabel>Business Tools</MenuLabel>
                <MenuList>
                    <li><NavLink to='/calculator' activeClassName="is-active">Abonnement calculator</NavLink></li>
                </MenuList>
                <MenuLabel>Externe links</MenuLabel>
                <MenuList>
                    <li><a href='https://colorhunt.co/' target='_blank'>Color Hunt</a></li>
                </MenuList>
          </Menu>
            </div>
        )
    }
}

export default Nav;